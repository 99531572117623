import React from 'react'
import Layout from '../components/layout'
import AddEvent from '../components/Calendar/AddNewEvent/AddEvent'

const AddNewEvent = () => {
  return (
    <Layout>
      <div className="box ">
        <AddEvent />
      </div>{' '}
    </Layout>
  )
}
export default AddNewEvent
