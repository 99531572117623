import React, { useEffect, useState } from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBAlert,
  toast,
  MDBNotification,
} from 'mdbreact'
import { AXIOSLINK } from '../../../utils/constants'

const axios = require('axios')

const AddEvent = () => {
  const [eventName, setEventName] = useState('')
  const [eventDate, setEventDate] = useState(new Date())
  const [eventLink, setEventLink] = useState('')
  const [eventPlace, setEventPlace] = useState('')
  const [isEventAdded, setIsEventAdded] = useState(false)

  const handleEventName = event => {
    setEventName(event.target.value)
    setIsEventAdded(false)
  }
  const handleEventDate = event => setEventDate(event.target.value)
  const handleEventLink = event => setEventLink(event.target.value)
  const handleEventPlace = event => setEventPlace(event.target.value)

  const handleSubmit = event => {
    event.preventDefault()
    const data = {
      cal_event_place: eventPlace,
      cal_event_name: eventName,
      cal_event_link: eventLink,
      cal_event_date: eventDate,
    }

    axios
      .post(AXIOSLINK + 'calendarevents', data)
      .then(result => {
        console.log(result)
        setIsEventAdded(true)
        setEventDate(new Date())
        setEventLink('')
        setEventName('')
        setEventPlace('')
      })
      .catch(err => {
        console.log({ err })
        console.log('====================================')
        console.log(`error creating Page:${err}`)
        console.log('====================================')
        //   reject(new Error(`error on page creation:\n${err}`))
      })
  }

  return (
    <div className="">
      <MDBContainer>
        <MDBCard className="eventCard">
          <div className="header pt-3 peach-gradient">
            <MDBRow className="d-flex justify-content-center">
              <h3 className="white-text mb-3 pt-3 font-weight-bold">
                {/* {jobPostingHeaderInstr[0].jp_emp_title} */}
                Add new event to the calender
              </h3>
            </MDBRow>
          </div>

          <MDBCardBody className="mx-4 mt-4">
            <MDBRow>
              <MDBCol md="11">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="positionTitle" className="grey-text">
                    Event Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="eventName"
                    value={eventName}
                    className="form-control"
                    onChange={handleEventName}
                    required
                  />
                  <br />

                  <label htmlFor="startDate" className="grey-text">
                    Event Date:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="Date"
                    id="eventDate"
                    value={eventDate}
                    className="form-control"
                    onChange={handleEventDate}
                    required
                  />
                  <br />

                  <label htmlFor="location" className="grey-text">
                    Link to the Event:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="eventLink"
                    value={eventLink}
                    className="form-control"
                    onChange={handleEventLink}
                  />
                  <br />

                  <label htmlFor="contactName" className="grey-text">
                    Event Place:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="eventPlace"
                    value={eventPlace}
                    className="form-control"
                    onChange={handleEventPlace}
                    required
                  />
                  <br />

                  <div className="text-center mt-4">
                    <MDBBtn color="warning" outline type="submit">
                      Submit
                      <MDBIcon far icon="paper-plane" className="ml-2" />
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>

        {isEventAdded ? (
          <MDBAlert color="success" dismiss>
            <strong>Success!!!</strong> Event added Successfully.
          </MDBAlert>
        ) : null}
      </MDBContainer>
    </div>
  )
}

export default AddEvent
